<template>

  <el-dialog v-model="dialogPayVisible$" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :title="`${dialogPayTitle$.text}．${dialogPayTitle$.price}`" :width="`${w}`"
    custom-class="subscription-dialog">

    <template #header>
      <div class="b-users__item__cover__wrapper">
        <!-- {{ dialogCurrentUser$?.profile_banner  }} -->
        <!-- <img src="https://public.onlyfans.com/files/thumbs/w480/s/s3/s3r/s3rmdoljmu4mzia35gj3jg5gzggmhyup1653830347/237262913/header.jpg" alt="2德文" class="b-users__item__cover"> -->
        <div class="rounded img-fluid" :style="`height: 200px;background-size: cover;background-position: center center;background-image: url(${dialogCurrentUser$?.profile_banner});`">
        </div>
      </div>
      <div class="b-profile__user">
        <div class="g-avatar">
          <el-avatar :size="100">
            <img :src="dialogCurrentUser$?.avatar" />
          </el-avatar>
        </div>
        <div class="b-profile__user__info m-with-status">
          <div class="b-profile__names">
            <div class="b-username-row">
              <router-link :to="`/${dialogCurrentUser$?.user_account}`" class="b-username">
                <div class="g-user-name m-verified m-lg-size"> {{ dialogCurrentUser$?.user_name }} </div>
              </router-link>
            </div>
            <div class="b-username-row m-width-limit">
              <router-link :to="`/${dialogCurrentUser$?.user_account}`">
                <div class="g-user-username">{{ dialogCurrentUser$?.user_account }} </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- <span>{{ dialogCurrentUser$ }}</span> -->
    <!-- <div v-if="isCard$" id="isCard"> -->
    <div id="isCard">
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">{{$t('label.automaticallyRenewsOnAPeriodicBasisYouCanCancelTheRenewalAtAnyTime')}}</div>
      </div>
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">{{$t('label.youCanFullyBrowseAllOfThisUserSSubscriptions')}}</div>
      </div>
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">{{$t('label.youCanMessageThisUserDirectly')}}</div>
      </div>
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#e44">
            <Close />
          </el-icon>
        </div>
        <div class="ps-1">{{$t('label.purchaseNotes')}}</div>
      </div>
      <div v-if="dialogPayTitle$.options === 'single' && step === 1" id="supscriptionOption">
        <h5 class="pt-3">{{$t('label.chooseASubscriptionOption')}}</h5>
        <el-row :gutter="12">
          <el-col :span="24">

            <!-- {{ dialogPayTitle$ }} -->
            <el-card shadow="hover" body-style="supscription-option" @click="selectedOption(2,dialogPayTitle$.type,dialogPayTitle$.text,dialogPayTitle$.price,'')">
              <div class="d-flex justify-content-between">
                <el-tag v-if="dialogPayTitle$.type === 5" class="ml-2" type="success">
                  {{ dialogPayTitle$.v }}
                </el-tag>
                <el-tag v-if="dialogPayTitle$.type === 6" class="ml-2" type="info">
                  {{ dialogPayTitle$.v }}
                </el-tag>
                <el-tag v-if="dialogPayTitle$.type === 4" class="ml-2" type="warning">
                  {{ dialogPayTitle$.v }}
                </el-tag>
                <div class="d-flex">
                  <span class="text-primary">
                    {{ dialogPayTitle$.text }}
                  </span>
                </div>
                <span class="text-primary"> {{ dialogPayTitle$.price }}</span>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

      <div v-if="dialogPayTitle$.options === 'multiple' && step === 1" id="supscriptionOption">
        <h5 class="pt-3">{{$t('label.chooseASubscriptionOption')}}</h5>
        <el-row :gutter="12">
          <el-col :span="24">
            <el-card v-for="(item, index) in dialogPayTitle$.promotions" :key="index" shadow="hover" body-style="supscription-option" @click="selectedOption(2,5,
                `${item.promotions_month}${$t('label.month')}．(省下${item.promotions_discount}`, item.promotions_amount,item.promotions_id)">
              <div class="d-flex justify-content-between">
                <el-tag class="ml-2" type="success">
                  促銷
                </el-tag>
                <div class="d-flex">
                  <span class="text-primary">
                    {{item.promotions_month }} 個{{$t('label.month')}}月．(省下{{item.promotions_discount}})
                  </span>{{$t('label.month')}}
                </div>$t('label.month')
                <span class=" text-primary">{{ item.promotions_amount }}</span>
              </div>
            </el-card>
            <el-card v-for="(item, index) in dialogPayTitle$.subscription_bundle" :key="index" shadow="hover"
              @click="selectedOption(2,6,`${item.subscription_bundle_duration}個月．(省下${item.subscription_bundle_discount}`,item.subscription_bundle_amount,item.subscription_bundle_id)">
              <div class="d-flex justify-content-between">
                <el-tag class="ml-2" type="info">
                  {{$t('label.tieUp')}}
                </el-tag>
                <div class="d-flex">
                  <span class="text-primary">
                    {{item.subscription_bundle_duration }} 個月．(省下{{item.subscription_bundle_discount}})
                  </span>
                </div>
                <span class="text-primary">{{ item.subscription_bundle_amount }}</span>
              </div>
            </el-card>
            <el-card v-for="(item, index) in dialogPayTitle$.program" :key="index" shadow="hover" @click="selectedOption(2,4,`每月．${item.program_title}`,item.program_price,item.program_id)">
              <div class="d-flex justify-content-between">
                <el-tag class="ml-2" type="warning">
                  {{$t('label.subscription')}}
                </el-tag>
                <div class="d-flex">
                  <span class="text-primary">
                    {{$t('label.perMonth')}}．{{ item.program_title }}
                  </span>
                </div>
                <span class="text-primary">{{ item.program_price }}</span>
              </div>
            </el-card>

          </el-col>
        </el-row>
      </div>
      <div v-if="step === 2">
        <div v-if="isCard$" id="supscriptionPayMethod">
          <h5 class="pt-3">{{ $t('label.chooseThePaymentMethod') }}</h5>
          <el-select class="w-100" :placeholder="$t('label.pleaseChooseAPaymentMethod')" v-model=" selectedPayMethod">
            <el-option v-for="item in PayOptions" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
          </el-select>
        </div>

        <div id="payDesc">
          <div class="pay-desc d-flex justify-content-between">
            <span class="pt-1">{{ $t('label.subscribeToWatchInstructions') }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <h5 class="pt-1">{{ $t('label.planSelection') }}</h5>
            <el-button size="small" type="primary" @click="selectedOption(1, '', '','','')">
              {{ $t('label.reselect') }}</el-button>
          </div>

          <div class="desc-list">
            <div class="d-flex justify-content-start">
              <span>{{ selectedPayOptionText }}</span>
            </div>
            <div class="d-flex justify-content-between">
              <div>
                <span>
                  {{ $t('label.theAmount') }}：{{toCurrency(selectedPayPrice)}}</span>
              </div>
              <div>
                <span>{{ $t('label.tax') }}：{{ toCurrency(taxPrice) }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <span>{{ $t('label.paidInTotal') }}：{{ toCurrency(total) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isCard$ && step === 2" id="isNotCard">
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">完整訪問此用戶的內容</div>
      </div>
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">直接消息給此用戶</div>
      </div>
      <div class="d-flex mb-0">
        <div class="d-flex align-items-center">
          <el-icon :size="iconSize" color="#090">
            <Check />
          </el-icon>
        </div>
        <div class="ps-1">隨時取消訂閱</div>
      </div>
      <div class="">
        <div class="content-btn d-grid p-2">
          <button type="submit" class="btn btn-primary rounded-pill" @click="showCardForm = !showCardForm">
            添加信用卡資訊
          </button>
        </div>
      </div>
      <iq-card v-if="showCardForm">
        <template v-slot:body>
          <form>
            <div class="row align-items-center">
              <div class="form-group col-sm-6">
                <label for="fname" class="form-label">{{$t('label.iDNumber')}}</label>
                <input type="text" v-model="form.user_sn" class="form-control" id="user_sn" name="user_sn" :placeholder="$t('label.pleaseEnterYourIDNumber')"
                  :class="{ 'is-invalid':  v$.form.user_sn.$dirty && !v$.form.user_sn.$pending && v$.form.user_sn.$invalid }" />
                <span v-if=" v$.form.user_sn.$dirty && !v$.form.user_sn.$pending && v$.form.user_sn.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group col-sm-6">
                <label for="fname" class="form-label">{{$t('label.email')}}</label>
                <input type="text" v-model="form.user_email" class="form-control" id="email" name="email" :placeholder="$t('label.emailPlaceholder')"
                  :class="{ 'is-invalid':  v$.form.user_email.$dirty && !v$.form.user_email.$pending && v$.form.user_email.$invalid }" />
                <span v-if=" v$.form.user_email.$dirty && !v$.form.user_email.$pending && v$.form.user_email.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>

              <div class="form-group col-sm-6">
                <label for="uname" class="form-label">{{$t('label.cardName')}}</label>
                <!-- <div class="fab fa-cc-visa ps-2"></div> -->
                <input type="text" v-model="form.user_real_name" class="form-control" id="card_number" name="card_number" :placeholder="$t('label.cardNamePlaceholder')"
                  :class="{ 'is-invalid':  v$.form.user_real_name.$dirty && !v$.form.user_real_name.$pending && v$.form.user_real_name.$invalid }" maxlength="19" />
                <span v-if=" v$.form.user_real_name.$dirty && !v$.form.user_real_name.$pending && v$.form.user_real_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>

              <div class="form-group col-sm-6">
                <label for="uname" class="form-label">{{$t('label.cardholderEnglishName')}}</label>
                <!-- <div class="fab fa-cc-visa ps-2"></div> -->
                <input type="text" v-model="form.user_english_name" class="form-control" id="user_english_name" name="user_english_name" :placeholder="$t('label.pleaseEnterTheEnglishNameOfTheCardholder')"
                  :class="{ 'is-invalid':  v$.form.user_english_name.$dirty && !v$.form.user_english_name.$pending && v$.form.user_english_name.$invalid }" maxlength="19" />
                <span v-if=" v$.form.user_english_name.$dirty && !v$.form.user_english_name.$pending && v$.form.user_english_name.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group col-sm-6">
                <label for="uname" class="form-label">{{$t('label.telephone')}}</label>
                <input type="text" v-model="form.user_cellphone" class="form-control" id="user_cellphone" name="user_cellphone" :placeholder="$t('label.pleaseEnterPhoneNumber')"
                  :class="{ 'is-invalid':  v$.form.user_cellphone.$dirty && !v$.form.user_cellphone.$pending && v$.form.user_cellphone.$invalid }" maxlength="19" pattern="[0-9]*" />
                <span v-if=" v$.form.user_cellphone.$dirty && !v$.form.user_cellphone.$pending && v$.form.user_cellphone.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group col-sm-6">
                <label for="uname" class="form-label">{{$t('label.address')}}</label>
                <input type="text" v-model="form.user_address" class="form-control" id="user_address" name="user_address" :placeholder="$t('label.pleaseEnterAddress')"
                  :class="{ 'is-invalid':  v$.form.user_address.$dirty && !v$.form.user_address.$pending && v$.form.user_address.$invalid }" maxlength="19" />
                <span v-if=" v$.form.user_address.$dirty && !v$.form.user_address.$pending && v$.form.user_address.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="form-group col-sm-12">
              <label for="type" class="form-label">{{$t('發卡銀行所在地')}}</label>
              <select v-model="form.type" class="form-control" id="type" name="type"
                :class="{ 'is-invalid': v$.form.type.$dirty && !v$.form.type.$pending && v$.form.type.$invalid }">
                <option value="">{{$t('請選擇發卡銀行所在地')}}</option>
                <option value="1">{{$t('海內')}}</option>
                <option value="2">{{$t('海外')}}</option>
              </select>
              <span v-if="v$.form.type.$dirty && !v$.form.type.$pending && v$.form.type.$invalid"
                class="invalid-feedback">{{ $t('messages.required') }}</span>
            </div>
            </div>
            <div class="d-flex justify-content-end">
              <button @click="onSubmit()" type="button" class="btn btn-primary me-2">
                {{$t('button.submit')}}
              </button>
            </div>
          </form>
        </template>
      </iq-card>
      <div v-if="step === 2" id="supscriptionPayMethod">
          <h5 class="pt-3">{{ $t('label.chooseThePaymentMethod') }}</h5>
          <el-select class="w-100" :placeholder="$t('label.pleaseChooseAPaymentMethod')" v-model=" selectedPayMethodWithoutEwallet">
            <el-option v-for="item in PayOptionsWithoutEwallet" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
    </div>
    <template #footer>
      <div v-if="isCard$" id="isCardFooter" class="dialog-footer">
        <el-button text @click="close">{{ $t('label.cancel') }}</el-button>
        <el-button v-if="step === 2" :loading="onLoading" type="primary" @click="orderCheckout(
          selectedPayMethod, selectedPayId, selectedPayType, number
        )">{{ $t('label.payment') }}</el-button>
      </div>
      <div v-if="!isCard$" id="isNotCardFooter" class="dialog-footer">
        <el-button text type="primary" @click="store.dispatch('User/dialogPayAction', false)">關閉</el-button>
        <el-button v-if="step === 2" :loading="onLoading" type="primary" @click="orderCheckout(
          selectedPayMethodWithoutEwallet, selectedPayId, selectedPayType, number
        )">{{ $t('label.payment') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Check, Close } from '@element-plus/icons-vue'
import { useConvert } from '@/utils/useConvert'
import { useCheckout } from '@/utils/useCheckout'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { commomData } from '@/utils/commonVmodel'
import { addCreditCard } from '@/api/creditCard'

export default {
  name: 'SubscriptionPay',
  components: {
    Check,
    Close
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  validations() {
    return {
      form: {
        user_email: { required, email },
        user_sn: { required },
        user_cellphone: { required },
        user_real_name: { required },
        user_english_name: { required },
        user_address: { required },
        type: { required }
      }
    }
  },
  setup() {
    const v$ = useVuelidate()
    const { orderCheckout, onLoading, PayOptions, PayOptionsWithoutEwallet } = useCheckout()
    const { toCurrency, payIdToString } = useConvert()
    const store = useStore()
    const step = ref(1)
    const selectedPayOptionText = ref('') // 已選的方案選項文字
    const selectedPayId = ref(0) // 付款id

    const selectedPayMethod = ref('ewallet') // 付款方式
    const selectedPayMethodWithoutEwallet = ref('e_collection') // 付款方式
    const selectedPayType = ref(0) // 訂單類型
    const selectedPayPriceFormat = ref(0) // 金額格式化
    const selectedPayPrice = ref(0) // 訂單金額
    const taxPercentage = ref(0.05) // 稅金％
    const taxPrice = ref(0) // 稅金

    const total = ref(0) // 總金額
    const iconSize = ref(17)
    const dialogPayVisible$ = computed(() => store.state.User.dialogPayVisible)
    const dialogPayTitle$ = computed(() => store.state.User.dialogPayTitle)
    const dialogCurrentUser$ = computed(() => store.state.User.dialogItem)
    const isCard$ = computed(() => store.state.User.user.is_card)

    const w = computed(() => {
      if (window.innerWidth > 1000 && window.innerWidth < 1200) {
        return '50%'
      } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
        return '40%'
      } else {
        return window.innerWidth - window.innerWidth / 10 + 'px'
      }
    })
    // _step 步驟
    // type 訂單類型
    // otpionText 訂閱選項文字
    // price 金額
    // payId 付款ID
    const selectedOption = (_step, type, otpionText, price, id) => {
      // let payId = 0
      // switch (type) {
      //   case 4: // 訂閱
      //     payId = dialogPayTitle$.value.item.program_id
      //     break
      //   case 5: // 促銷
      //     payId = dialogPayTitle$.value.item.promotions_id
      //     break
      //   case 6: // 訂閱綑綁
      //     payId = dialogPayTitle$.value.item.subscription_bundle_id
      //     break
      //   default:
      //     break
      // }
      if (id === '') {
        selectedPayId.value =
          dialogPayTitle$.value.item[`${payIdToString(type)}_id`]
      } else {
        selectedPayId.value = id
      }
      console.log('selectedOption', type, price, selectedPayId.value)

      // dialogPayTitle$.value.item[`${payIdToString(type)}_id`]

      if (_step === 2) {
        selectedPayOptionText.value = otpionText
        selectedPayType.value = type
        selectedPayPriceFormat.value = price
        selectedPayPrice.value = parseFloat(price.replace(/[^0-9.-]+/g, ''))
        taxPrice.value = Math.round(selectedPayPrice.value * 0.05) // 稅金
        total.value = taxPrice.value + selectedPayPrice.value // 總金額
        step.value = 2
      } else {
        setDefaultOpt()
        step.value = 1
      }
    }

    const close = () => {
      step.value = 1
      setDefaultOpt()
      store.dispatch('User/dialogPayAction', false)
    }

    const setDefaultOpt = () => {
      selectedPayId.value = 0
      selectedPayOptionText.value = ''
      selectedPayType.value = 0
      selectedPayPriceFormat.value = 0
      selectedPayPrice.value = 0
      taxPrice.value = 0 // 稅金
      total.value = 0 // 總金額
    }
    // const options = [
    //   {
    //     value: 'ewallet',
    //     label: '信用卡綁定(60秒內入帳)'
    //   },
    //   {
    //     value: 'e_collection',
    //     label: 'ATM轉帳(5分內入帳)'
    //   },
    //   {
    //     value: 'cstorecode',
    //     label: '超商代碼(1小時內入帳)'
    //   }
    // ]
    return {
      setDefaultOpt,
      close,
      selectedOption,
      dialogCurrentUser$,
      dialogPayVisible$,
      w,
      iconSize,
      PayOptions,
      PayOptionsWithoutEwallet,
      selectedPayMethodWithoutEwallet,
      store,
      dialogPayTitle$,
      isCard$,
      step,
      selectedPayOptionText,
      selectedPayMethod,
      selectedPayType,
      selectedPayPrice,
      selectedPayPriceFormat,
      taxPrice,
      total,
      taxPercentage,
      toCurrency,
      orderCheckout,
      selectedPayId,
      payIdToString,
      onLoading,
      v$
    }
  },
  data() {
    return {
      commomData: commomData(),
      form: {
        user_sn: '',
        user_cellphone: '',
        user_real_name: '',
        user_email: '',
        user_english_name: '',
        user_address: ''
      },
      showCardForm: false
    }
  },
  watch: {
    'form.card_number'() {
      // Card number without dash (-)
      let realNumber = this.form.card_number.replace(/-/gi, '')

      // Generate dashed number
      let dashedNumber = realNumber.match(/.{1,4}/g)

      // Replace the dashed number with the real one
      this.form.card_number = dashedNumber.join('-')
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      addCreditCard(this.form)
        .then(res => {
          console.log(res)
          this.showCardForm = false
          this.$store.dispatch('User/fetchUserAction')
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>
<style scoped>
.pay-desc {
  font-size: 10px;
}
</style>